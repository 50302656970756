<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-10 10:58:31
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-10 13:21:36
 * @FilePath: /AI-APP-henan/src/components/Bubbles/Feedback.vue
 * @Description:
-->
<style lang="scss" scoped>
.message-btns {
  display: flex;
  // width: 80%;
  margin: 0 auto;
  padding-bottom: 0.14rem;
  .weak {
    @include button();
    margin-left: 0.15rem;
    margin-right: 0.15rem;
  }
  .focus {
    @include buttonBright();
    margin-right: 0.15rem;
    margin-left: 0.15rem;
  }
  .button {
    width: 0.8rem;
    height: 0.3rem;
    line-height: 0.3rem;
  }
  &.is-feedbacked .button {
    cursor: not-allowed;
  }
}
.message-result {
  font-size: 0.12rem;
  line-height: 1.5em;
  color: #201e1e;
  text-align: center;
  padding-bottom: 0.14rem;
}
</style>

<template>
  <div class="component-root">
    <!-- 业务推荐 -->
    <div class="message-header">
      <em class="icon-dot"></em>以上答案是否对您有帮助
    </div>
    <div class="message-btns" :class="{ 'is-feedbacked': isFeedBacked }">
      <!-- 20221010 根据用户习惯调整按钮顺序 -->
      <button class="button weak" @click="feedbackHelpful(2)">无帮助</button>
      <button class="button focus" @click="feedbackHelpful(1)">有帮助</button>
    </div>
    <div
      class="message-result"
      v-show="isFeedBacked"
    >{{ isFeedBacked === 1 ? '感谢您的认可，我会继续加油。' : '抱歉！没有能够帮到您，我们会持续改进。' }}</div>
  </div>
</template>

<script>
import { feedback } from '@/api/chat'
import { mapState } from 'vuex'

export default {
  name: 'BubbleFeedback',
  props: {
    message: Object,
    index: Number
  },
  data () {
    return {
      isFeedBacked: false
    }
  },
  computed: {
    ...mapState(['messages'])
  },
  methods: {
    async feedbackHelpful (useful) {
      // 反馈 过的不再反馈
      if (this.isFeedBacked) {
        return false
      }
      try {
        await feedback({
          applyId: this.message.applyId,
          useful
        })
        this.isFeedBacked = useful

        if (this.index === this.messages.length - 1) {
          // 最后一条需要刷新列表定位
          this.$store.commit('updateFreshNeed', true)
        }
      } catch (e) {
        this.isFeedBacked = false
      }
    }
  }
}
</script>
